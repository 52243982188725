import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'app/interfaces/Auth';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private apiUrl: string = environment.apiUrl
  currentUser: Observable<Auth>;
  currentUserSubject: BehaviorSubject<Auth>;

  constructor(private http: HttpClient, private toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<Auth>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  loginAuth(email: string, password: string) {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    let body = `email=${email}&password=${password}`;
    return this.http.post<Auth>(`${this.apiUrl}/auth/sign-in-admin`, body, options).pipe(
      map(auth => {
        if (auth && auth.access_token) {
          localStorage.setItem('currentUser', JSON.stringify(auth));
          this.toastrService.success(
            `You have successfully logged in as an user.`,
            `👋 Welcome, ${auth.user.name} !`,
            { toastClass: 'toast ngx-toastr', closeButton: true }
          );

          this.currentUserSubject.next(auth);
        }
        return auth;
      })
    )
  }

  public get currentUserValue(): Auth {
    return this.currentUserSubject.value;
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}