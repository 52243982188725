import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from 'app/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class DashboardGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;
    const noExistUser =
      !currentUser || !currentUser.user || !currentUser?.user?.userType?.type;

    if (noExistUser) {
      this._authenticationService.logout();
      this._router.navigate(['/auth/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    const events = [
      { name: 'Airport', path: '/aeropuerto' },
      { name: 'Auditorium', path: '/auditorio' },
      { name: 'JWPolanco', path: '/jw-polanco' },
      { name: 'Anzures', path: '/anzures' },
      { name: 'Monument', path: '/monumento' },
      { name: 'Mapfre', path: '/mapfre' },
      { name: 'Hyatt', path: '/hyatt' },
      { name: 'Varsovia', path: '/colonia-juarez' },
      { name: 'historicDistric', path: '/centro-historico' },
    ];
    const eventFind = events.find(
      (e) => e.name === currentUser?.user?.userType?.type
    );

    if (eventFind) {
      this._router.navigate([eventFind.path]);      
      return false;
    }
    const hasntRole =
      route.data.roles &&
      !route.data.roles.includes(currentUser?.user?.userType?.type);

    if (hasntRole) {
      this._router.navigate(['/errors/not-authorized']);
      return false;
    }

    return true;
  }
}
