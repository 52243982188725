import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private spinnerService: NgxSpinnerService) { }
  requestCounter = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.beginRequest()
    return next.handle(req).pipe(
      finalize(() => this.endRequest())
    )
  }

  beginRequest() {
    this.requestCounter++;
    if (this.requestCounter === 1) {
      this.spinnerService.show();
    }
  }

  endRequest() {
    this.requestCounter--;
    if (this.requestCounter === 0) {
      this.spinnerService.hide()
    }
  }
}
